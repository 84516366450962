import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { FIREBASE_APP } from 'ag-common-svc/public-api';
import './date.prototype';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorInterceptor } from './auth/helpers/error.interceptor';
import { IonicModule } from '@ionic/angular';
import { STRIPE_PUBLIC_KEY } from 'ag-common-svc/lib/components/ag-stripe-modal/ag-stripe-modal.models';
import { NgxStripeModule } from 'ngx-stripe';
import { CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, initializeFirestore } from 'firebase/firestore';
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_MAP_ID } from 'ag-common-lib/lib/models/utils/google-maps-injection-token';
import { checkAndUpdateCache } from 'ag-common-svc/lib/utils/app-version.util';
import { USE_CLOUD_FUNCTIONS_EMULATOR } from 'ag-common-svc/lib/injections/use-cloud-functions-emulator';

export const fireBaseApp: FirebaseApp = initializeApp(environment.firebaseConfig);

const db = initializeFirestore(fireBaseApp, {
  experimentalForceLongPolling: true,
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

try {
  enableIndexedDbPersistence(db)
    .then(() => checkAndUpdateCache(db))
    .catch(error => {
      debugger;
    });
} catch (error) {
  debugger;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    IonicModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripe_payments_public_key),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('main-sw.js', {
      enabled: environment.isPWAEnabled,
      registrationStrategy: 'registerImmediately',
    }),
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: FIREBASE_APP,
      useValue: fireBaseApp,
    },
    {
      provide: USE_CLOUD_FUNCTIONS_EMULATOR,
      useValue: false,
    },
    {
      provide: STRIPE_PUBLIC_KEY,
      useValue: environment.stripe_payments_public_key,
    },
    {
      provide: GOOGLE_MAPS_API_KEY,
      useValue: environment.google.mapsAPIKey,
    },
    {
      provide: GOOGLE_MAPS_MAP_ID,
      useValue: environment.google.mapId,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
